<template>
  <div>
    <cash-desks />
  </div>
</template>

<script>
import CashDesks from '@/components/Devices/CashDesks/CashDesks.vue'

export default {
  components: { CashDesks },
  name: 'CashDesk',
}
</script>
